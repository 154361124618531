* {
  box-sizing:border-box;
  font-family: Arial, Helvetica, sans-serif;
  color: #1c2833;
}

body {
  background-color: #EAF4F4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  margin: 0;
  padding: 0;
}

div.container {
  max-width: 1080px;
  margin: auto;
  background-color: white;
  padding: 2rem;
  height: 100%;
}

.actions {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;;
}

button {
  margin-right: 1rem;
  border-radius: 2rem;
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009fd4;
  color: white;
  border: none;
  height: 40px;
  width: 180px;
  font-size: 16px;
  cursor: pointer;
}

button:focus {
  outline: none;
  border: 3px solid #2387a8;
}

button:hover {
  opacity: 0.7;
}

table {
  width:100%;
  max-width:1024px;
  min-width:300px;
  table-layout: fixed;
  border-collapse: collapse;
}

table th {
  padding: 1rem 0 0.5rem;
  font-size: 32px
}

table td {
  padding:4px;
  border: 1px solid lightgray;
  text-align:center;
  height:28px;
}

.called {
  background-color:#d5e7ee;
}

div.number-summary {
  border-radius: 10px;
  background-color: #EAF4F4;
  border: 1px solid lightgray;
  padding: 1.5rem 1rem;
  margin-top: 1rem;
}

div.number-summary h2 {
  margin-top: 0;
}
